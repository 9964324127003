<template>
  <section id="dashboard-prediccion">
    <b-row>
      <b-col>
        <b-overlay :show="showOverlay" rounded="sm">
          <b-form-textarea
            id="textarea"
            placeholder="Enter something..."
            rows="10"
            max-rows="6"
            @change="debugChange"
            v-if="debug"
          ></b-form-textarea>

          <ecualizador :metrics="input_variables" @search-change="searchChange">
            <template v-slot:footer-ecualizador>
              <div></div>
            </template>
          </ecualizador>

          <template #overlay>
            <div class="text-center">
              <b-spinner label="Loading..." />
              <p id="cancel-label">{{ overlayMessage }}</p>
            </div>
          </template>
        </b-overlay>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <BrunaForm
          ref="ajustesOptimizadorRef"
          :fields="this.ajustes_optimizador.fields"
          :card="this.ajustes_optimizador.card"
        >
  
        </BrunaForm>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <BrunaForm
          ref="ajustesCapacidadesRef"
          :fields="this.ajustes_capacidades.fields"
          :card="this.ajustes_capacidades.card"
        >

        </BrunaForm>
      </b-col>
    </b-row>


    <b-row>
      <b-col>
        <BrunaForm
          ref="ajustesMezclaObjetivoRef"
          :fields="this.ajustes_mezcla_objetivo.fields"
          :card="this.ajustes_mezcla_objetivo.card"
        >

        <template v-slot:footer>
            <b-button size="sm" @click="simulate" variant="primary"
              >Simular</b-button
            >
          </template>


        </BrunaForm>
      </b-col>
    </b-row>

    <!--
    <b-row>
      <b-col>
        <BrunaForm
          ref="ajustes2Ref"
          :fields="this.ajustes.fields"
          :card="this.ajustes.card"
        >
          <template v-slot:footer>
            <b-button size="sm" @click="simulate" variant="primary"
              >Simular</b-button
            >
          </template>
        </BrunaForm>
      </b-col>
    </b-row>
-->
    <b-row>
      <b-col sm="12">
        <ag-grid-table
          ref="grid-historico-runs"
          :debug="true"
          :configUrl="historicoOptimizationsConfig"
          :dataUrl="historicoOptimizationsData"
          @gridReady="onGridReady"
          @getRowsLoaded="onGetRowsLoaded"
          rowSelection="multiple"
          :rowClassRules="rowClassRules"
          :pinnedTopRowData="pinnedTopRowData"
          @selectionChanged="onSelectionChanged"
          @actionReceived="actionReceived"
          :getRowNodeId="getRowNodeId"
          :actions="actions" :helper="true"
        >
          <template v-slot:actions>
            <div>
              <b-button-toolbar
                aria-label="Toolbar with button groups and input groups"
                justify
              >
                <b-button-group style="margin-bottom: 1rem">
                  <b-button
                    size="sm"
                    :disabled="disableCompareRuns"
                    @click="compareRuns()"
                    variant="primary"
                    >{{ $t("Comparar") }}</b-button
                  >
                </b-button-group>
              </b-button-toolbar>
            </div>
          </template>

        </ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>
    
    <script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'

import VueSlider from 'vue-slider-component'
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";


import useApiServices from '@/services/useApiServices.js';

import HistoricoPlanificacionesActions from "./HistoricoPlanificacionesActions.vue";

import Ecualizador from "./Planificador/Ecualizador.vue";


import axios from "@axios";
var qs = require('qs');

import
{
  BRow,
  BCol,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,

  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

  BOverlay,
  BSpinner


} from "bootstrap-vue";



export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,

    VueSlider,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,

    Ecualizador,

    BOverlay,
    BSpinner,

    BrunaForm,



    historicoPlanificacionesActions: HistoricoPlanificacionesActions


  },

  methods: {

    debugChange(value)
    {

      console.log(value)

      let decoded = JSON.parse(value)





    },


    roundValue(value, decimales = 2)
    {
      let tmp = Math.pow(10, decimales)

      return Math.round(value * tmp) / tmp
    },


    actionReceived(action_name, data, params)
    {
      console.log("actionReceived", { action_name, data, params })
    },

    searchChange(value)
    {
      console.log(value)

      this.input_variables.forEach(metric =>
      {

        if (metric.name.match(new RegExp(value, 'i')))
        {
          metric.show = true
        } else
        {
          metric.show = false

        }



      })

    },


    compareRuns()
    {

      this.$router.push({ name: 'compare-simulations', query: { ids: this.selectedRows.map(run => run.id) } })
    },




    onGridReady(gridApi, gridColumnApi, params)
    {

      this.gridApi = gridApi

      console.log("Planificaciones > onGridReady", { gridApi, gridColumnApi, params })



    },

    onSelectionChanged(selectedRows)
    {

      this.selectedRows = selectedRows

      this.disableCompareRuns = (selectedRows.length < 2)
      this.disableRellenar = !(selectedRows.length == 1)

    },

    onGetRowsLoaded(gridApi, gridColumnApi, params)
    {
      console.log("Planificaciones > onGetRowsLoaded", { gridApi, gridColumnApi, params })

      this.tableLoaded = true;


    },

    simulate()
    {



      Promise.all([
        this.$refs.ajustesOptimizadorRef.validate(),
        this.$refs.ajustesCapacidadesRef.validate(),
        this.$refs.ajustesMezclaObjetivoRef.validate(),

      ]).then(([ajustesOptimizadorValid, ajustesCapacidadesValid, ajustesMezclaObjetivoValid]) =>
      {

        console.log({ ajustesOptimizadorValid, ajustesCapacidadesValid, ajustesMezclaObjetivoValid })

        if (ajustesOptimizadorValid && ajustesCapacidadesValid && ajustesMezclaObjetivoValid)
        {

          let ajustes_optimizador = this.ajustes_optimizador.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {});
          let ajustes_capacidades = this.ajustes_capacidades.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {});
          let ajustes_mezcla_objetivo = this.ajustes_mezcla_objetivo.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {});

          let ajustes = {...ajustes_optimizador, ...ajustes_capacidades, ...ajustes_mezcla_objetivo};


          this.$bvModal.msgBoxConfirm('Se creara una nueva simulación', {
            title: 'Confirmación',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Continuar',
            cancelTitle: 'Cancelar',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
            .then(value =>
            {

              if (value)
              {

                let data = {
                  service_name: this.service_name,
                  ajustes: ajustes,
                  data: this.input_variables.reduce((acumulador, variable) => { acumulador[variable.name] = variable.value; return acumulador }, {})
                };

                console.log(data)

                //this.overlayMessage = "Cargando predicción..."
                //this.showOverlay = true

                axios.post(useApiServices.modelsCreateInvocationUrl, data)
                  .then((response) =>
                  {

                    console.log(response)

                    //this.showOverlay = false
                    this.$refs['grid-historico-runs'].refreshRows()

                  })
                  .catch((error) =>
                  {
                    //this.showOverlay = false

                  });

              }

            })
            .catch(err =>
            {
              // An error occurred
            })




        }


      });





    },

    accionDescargar(data)
    {
      console.log(data)

      if (data.data.output_data == null)
      {
        return
      }

      let optimization_id = data.data.id;

      let query = qs.stringify({ optimization_id: optimization_id })

      useApiServices.getDownloadFile(useApiServices.detalleOptimizacion.detalleOptimizacionDownloadExcel  + "?" + query)


    },

    accionVerDetalles(data)
    {

      console.log(data)

      if (data.data.output_data == null)
      {
        return
      }

      let optimization_id = data.data.id;



      this.$router.push({ name: 'optimization-details', query: { optimization_id: optimization_id } })

    },


    loadVariables()
    {
      this.overlayMessage = "Cargando variables..."
      this.showOverlay = true

      return useApiServices.modelsRead({ name: this.model_name })
        .then((response) =>
        {

          let input_variables = response.data.input_variables.map(variable =>
          {

            let min = Math.floor(variable.meta_data.minimo)
            let max = Math.ceil(variable.meta_data.maximo)

            let value = min + (max - min) / 2

            if (variable.meta_data.options)
            {
              return {
                name: variable.name,
                value: 0,
                data: variable.meta_data.options,
                show: false,
                disabledInput: true

              }
            }
            else
            {
              return {
                name: variable.name,
                value: value,
                min: min,
                max: max,
                marks: [min, value, max],
                interval: 0.01,
                show: false,
                disabledInput: false

              }

            }



          });

          this.input_variables = input_variables

          this.showOverlay = false

        })
        .catch((error) =>
        {

        });

    }


  },


  beforeUnmount()
  {
    this.$pusher.unsubscribe('optimizations');
  },

  beforeRouteLeave(to, from, next)
  {

    this.$pusher.unsubscribe('optimizations');

    next()
  },


  mounted()
  {

    let self = this;

    //Se escucha a la llegada de las notificaciones de pusher

    var channel = this.$pusher.subscribe('optimizations');

    channel.bind('OptimizationUpdateEvent', function (data)
    {

      let userData = JSON.parse(localStorage.getItem("userData"));

      if (userData && userData.client.id == data.client_id)
      {
        let invocation_id = data.invocation_id

        useApiServices.getInvocation(invocation_id)
          .then((response) =>
          {
            let invocation = response.data

            console.log(invocation)

            if (self.tableLoaded && self.$refs['grid-historico-runs'])
            {
              self.$refs['grid-historico-runs'].updateRow(invocation_id, invocation)
            }



          })
          .catch(function (error) {
            console.log(error)
          })



      }


    });




  },




  created()
  {

    this.loadVariables()
      .then(() =>
      {


      })



  },


  data()
  {

    let debug = (this.$route.query.debug == '1')


    return {

      model_name: "CMP-CV12-14-OPT",
      service_name: "CMP-CV12-14-OPT",

      getRowNodeId: (params) =>
      {
        return params.id;
      },

      actions: [
        { name: "Ver Detalles", icon: "DownloadCloudIcon", action: this.accionVerDetalles },
        { name: "Descargar", icon: "DownloadCloudIcon", action: this.accionDescargar },
      ],

      debug: debug,

      showOverlay: false,
      overlayMessage: "Cargando...",
      tableLoaded: false,



      rowClassRules: {

        'escenario-base': (params) =>
        {
          if (params.data == undefined)
          {
            return false;
          }

          return params.data.default === 1;
        }

      },

      gridApi: null,

      itemsData: [],
      pinnedTopRowData: [],

      historicoOptimizationsConfig: useApiServices.historicoOptimizationsConfig,
      historicoOptimizationsData: useApiServices.historicoOptimizationsData,

      disableCompareRuns: true,
      disableRellenar: true,
      selectedRows: [],



      input_variables: [],


      ajustes_optimizador: {
        card: {
          title: "Simulador",
          subTitle: "Selecciona el origen de datos que utilizarás para la simulación",
          tooltip: "",
          sidebarContent: {
            title: "Simulador",
            body: '<ul><li>Elegir nombre de simulación o dejar nombre por omisión.</li><br><li>Elegir el número de generaciones que se desea para la simulación (las generaciones son el número de veces que se hace evolucionar el algoritmo).</li><br><li>Seleccionar la función objetivo (en este caso encontramos una, pero puede escalar a más).</li></ul>'
          }
        },
        fields: [


        {
            label: "Nombre de Simulación",
            type: "input",
            id: 'planification_name',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: "Sim_" + Date.now()
          },


          {
            label: "N° Máximo de Generaciones",
            type: "select",
            id: 'numero_max_generaciones',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 100, text: "100 generaciones" },
              { value: 200, text: "200 generaciones" },
              { value: 300, text: "300 generaciones" },
              { value: 400, text: "400 generaciones" },
              { value: 500, text: "500 generaciones" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 300
          },



          {
            label: "Tipo Función Objetivo",
            type: "select",
            id: 'tipo_funcion_objetivo',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 'default', text: "Maximizar FeMag y %Mag Mezcla" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 'default'
          },
         ]
      },


      ajustes_capacidades: {
        card: {
          title: "Capacidades",
          subTitle: "Selecciona el origen de datos que utilizarás para la simulación",
          tooltip: "",
          sidebarContent: {
            title: "Capacidades",
            body: '<ul><li>Seleccionar los flujos mínimos, máximos y críticos para cada correa y el flujo objetivo deseado.</li><br><li>En caso de querer mantener los valores estándares, dejar los que vienen por defecto.</li></ul>'
          }
        },
        fields: [

        {
            label: "CV12 Flujo Mínimo (Ton/Hr)",
            type: "input",
            id: 'cv12_flujo_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0
          },


          {
            label: "CV12 Flujo Máximo (Ton/Hr)",
            type: "input",
            id: 'cv12_flujo_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 1100
          },


          {
            label: "CV14 Flujo Mínimo (Ton/Hr)",
            type: "input",
            id: 'cv14_flujo_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0
          },


          {
            label: "CV14 Flujo Máximo (Ton/Hr)",
            type: "input",
            id: 'cv14_flujo_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 1100
          },


          {
            label: "Flujo Critico Mínimo (Ton/Hr)",
            type: "input",
            id: 'total_flujo_min_critico',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 700
          },

          {
            label: "Flujo Objetivo (Ton/Hr)",
            type: "input",
            id: 'total_flujo_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 950
          },

          {
            label: "Flujo Critico Máximo (Ton/Hr)",
            type: "input",
            id: 'total_flujo_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 1100
          },


         
         ]
      },



      ajustes_mezcla_objetivo: {
        card: {
          title: "Mezcla Objetivo",
          subTitle: "Selecciona el origen de datos que utilizarás para la simulación",
          tooltip: "",
          sidebarContent: {
            title: "Mezcla Objetivo",
            body: '<ul><li>Configurar los valores objetivos tanto de magnetismo como de calidades para la realización de mezcla.</li><br><li>En caso de querer mantener los valores estándares, dejar los que vienen por defecto.</li><br><li>Para realizar la simulación después de configurar todos los parámetros, hacer clic en el botón <strong>“simular”<strong>.</li></ul>'
          }
        },
        fields: [

          {
            label: "FeMag Mezcla Mínimo",
            type: "input",
            id: 'femag_ponderado_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 31
          },

          {
            label: "FeMag Mezcla Máximo",
            type: "input",
            id: 'femag_ponderado_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 33
          },


          {
            label: "Magnetismo Mezcla Mínimo",
            type: "input",
            id: 'magnetismo_ponderado_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 86
          },

          {
            label: "Magnetismo Mezcla Máximo",
            type: "input",
            id: 'magnetismo_ponderado_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 89
          },




        ]
      },



      /*

      ajustes: {
        card: {
          title: "Ajustes",
          subTitle: "Selecciona el origen de datos que utilizarás para la planificación",
          tooltip: "",
          sidebarContent: {
            title: "Ajustes",
            body: ' '
          }
        },
        fields: [

          {
            label: "Nombre de Planificación",
            type: "input",
            id: 'planification_name',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: "Opt_" + Date.now()
          },

          {
            label: "N° Máximo de Generaciones",
            type: "select",
            id: 'numero_max_generaciones',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 100, text: "100 generaciones" },
              { value: 200, text: "200 generaciones" },
              { value: 300, text: "300 generaciones" },
              { value: 400, text: "400 generaciones" },
              { value: 500, text: "500 generaciones" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 300
          },



          {
            label: "Tipo Función Objetivo",
            type: "select",
            id: 'tipo_funcion_objetivo',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 'default', text: "Maximizar FeMag y %Mag Mezcla" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 'default'
          },





          {
            label: "CV12 Flujo Mínimo (Ton/Hr)",
            type: "input",
            id: 'cv12_flujo_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0
          },


          {
            label: "CV12 Flujo Máximo (Ton/Hr)",
            type: "input",
            id: 'cv12_flujo_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 1100
          },


          {
            label: "CV14 Flujo Mínimo (Ton/Hr)",
            type: "input",
            id: 'cv14_flujo_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0
          },


          {
            label: "CV14 Flujo Máximo (Ton/Hr)",
            type: "input",
            id: 'cv14_flujo_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 1100
          },


          {
            label: "Flujo Objetivo Critico (Ton/Hr)",
            type: "input",
            id: 'total_flujo_min_critico',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 700
          },

          {
            label: "Flujo Objetivo (Ton/Hr)",
            type: "input",
            id: 'total_flujo_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 950
          },

          {
            label: "Flujo Total Máximo (Ton/Hr)",
            type: "input",
            id: 'total_flujo_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 1100
          },


          {
            label: "FeMag Mezcla Mínimo",
            type: "input",
            id: 'femag_ponderado_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 31
          },

          {
            label: "FeMag Mezcla Máximo",
            type: "input",
            id: 'femag_ponderado_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 33
          },


          {
            label: "Magnetismo Mezcla Mínimo",
            type: "input",
            id: 'magnetismo_ponderado_min',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 86
          },

          {
            label: "Magnetismo Mezcla Máximo",
            type: "input",
            id: 'magnetismo_ponderado_max',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 89
          },




        ]
      },
*/




    };
  },
};
    </script>
    <style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
  
  
  <style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}
</style>
  
  <style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
    